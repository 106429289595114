const beneficiaryEnumerators = {
  benIdentificationType: [
    'NRIC',
    'Old IC',
    'Passport',
    'Army/Police ID',
  ],
  benRelationship: [
    'Fiance',
    'Spouse',
    'Partner',
    'Parent',
    'GrandParent',
    'GreatGrandParent',
    'Child',
    'GrandChild',
    'GreatGrandChild',
    'Sibling',
    //'Cohabitee',
    //'UnmarriedPartner',
    //'SpouseParent',
    // 'ChildPrevious',
    // 'SpouseChildPrevious',
    // 'Solicitor',
    // 'NominatedSolicitor',
    'Husband',
    'Wife',
    'Son',
    'Daughter',
    'Father',
    'Mother',
    'Sister',
    'Brother',
    'Sister-In-Law',
    'Brother-In-Law',
    'Father-In-Law',
    'Mother-In-Law',
    'Son-In-Law',
    'Daughter-In-Law',
    'Grandson',
    'Granddaughter',
    'Uncle',
    'Aunt',
    'Cousin',
    'Nephew',
    'Niece',
    'Grandfather',
    'Grandmother',
    'Great-grandfather',
    'Great-grandmother',
    'Great-grandson',
    'Great-granddaughter',
    'Relative',
    'Friend',
    'Business Partner',
    'Charity',
  ],
  benOldRelationship: [
    'Fiance',
    'Spouse',
    'Partner',
    'Parent',
    'GrandParent',
    'GreatGrandParent',
    'Child',
    'GrandChild',
    'GreatGrandChild',
    'Sibling',
  ],
  familyMember: [
    'Fiance',
    'Spouse',
    'Parent',
    'GrandParent',
    'GreatGrandParent',
    'Child',
    'GrandChild',
    'GreatGrandChild',
    'Sibling',
    'Relative',
    //'Cohabitee',
    //'UnmarriedPartner',
    //'SpouseParent',
    // 'ChildPrevious',
    // 'SpouseChildPrevious',
    // 'Solicitor',
    // 'NominatedSolicitor',
    'husband',
    'wife',
    'father',
    'mother',
    'son',
    'daughter',
    'paternalGrandfather',
    'paternalGrandmother',
    'maternalGrandfather',
    'maternalGrandmother',
    'grandson',
    'granddaughter',
    'fullBrother',
    'consanguineBrother',
    'uterineBrother',
    'fullSister',
    'consanguineSister',
    'uterineSister',
    'fullBrotherSon',
    'consanguineBrotherSon',
    'fullPaternalUncle',
    'consanguinePaternalUncle',
    'fullPaternalUncleSon',
    'consanguinePaternalUncleSon',
  ],
};

export default beneficiaryEnumerators;
