import moment, { Moment } from 'moment';
import { getHistory } from 'src/modules/store';

/**
 * Trim trailing whitespace from an address, and append a comma if one
 * doesn't already exist at the end.
 * @param {string} address
 * @return {string}
 */
export const trimAddress = (address: string): string => {
  const trimmedAddress = address?.trimEnd() ?? '';

  // Append comma if it doesn't exist at the end
  if (
    !trimmedAddress.endsWith(',') &&
    trimmedAddress.length > 0
  ) {
    return trimmedAddress + ',';
  }

  return trimmedAddress;
};

export const formatAddress = (
  addresses: string[],
): string => {
  var new_addresses = addresses
    .filter(
      (o) => o !== '' && o !== null && o !== undefined,
    )
    .map((o, ind) => {
      var removeBreakAddress = o
        .split('\n')
        .filter(
          (obj) =>
            obj !== '' && obj !== null && obj !== undefined,
        )
        .map((obj) => {
          var address = obj.trimEnd();

          if (!address.endsWith(',')) {
            address = address + ', ';
          } else {
            address = address + ' ';
          }

          return address;
        })
        .join('');

      var address = removeBreakAddress.trimEnd();

      if (!address.endsWith(',')) {
        address = address + ', ';
      } else {
        address = address + ' ';
      }

      return address;
    });

  var new_address = new_addresses.join('').trimEnd() || '';

  if (new_address.endsWith(',')) {
    new_address = new_address.slice(0, -1);
  }

  return new_address || '';
};

/**
 * Format a given number as a currency value, with the appropriate
 * number of decimal places and thousands separators.
 *
 * @param {number|string} value - The number to be formatted
 * @return {string} The formatted currency value
 * @throws {Error} If the value is not a number
 */
export const formatCurrencyValue = (
  value: number | string,
): string => {
  try {
    let sourceVal =
      typeof value === 'string' ? parseFloat(value) : value;
    if (isNaN(sourceVal)) {
      throw new Error(`Invalid value: ${value}`);
    }
    return sourceVal
      .toFixed(2)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  } catch (e) {
    console.error(
      'formatCurrencyValue Err: %s %o',
      e,
      value,
    );
    return '-999999999.99';
  }
};

type formAction = 'edit' | 'new' | 'home' | 'view';

/**
 * Checks if the given value is an empty object.
 *
 * @param {any} value - The value to check.
 * @return {boolean} Returns true if the value is an empty object, otherwise false.
 */
export const isEmptyObjectOrString = (value: any) => {
  if (value === null || value === undefined) {
    return true;
  }

  if (Array.isArray(value) && value.length === 0) {
    return true;
  }

  if (
    typeof value === 'object' &&
    Object.keys(value).length === 0
  ) {
    return true;
  }

  if (
    Object.prototype.toString.call(value) ===
    '[object Object]'
  ) {
    return true;
  }

  if (typeof value === 'string' && value.trim() === '') {
    return true;
  }

  return false;
};

/**
 * Navigates to a form action based on the provided action and record ID.
 * @param {moduleBaseUrl} - The module url (e.g. 'specific-gift', 'beneficiary')
 * @param {formAction} action - The action to navigate to (e.g. 'edit', 'new', 'home', 'view')
 * @param {string} [recId] - The record ID to use for the navigation (optional)
 * @param {boolean} [returnUrl=false] - Whether to return the URL instead of navigating to it (optional)
 * @return {string} The URL of the navigation target if returnUrl is true, otherwise undefined
 */
export const formActionNavigator = (
  moduleBaseUrl: string,
  action: formAction,
  recId?: string,
  returnUrl: boolean = false,
) => {
  const getUrl = (
    action: formAction,
    recId?: string,
  ): string => {
    const pathPrefix = window.location.pathname.includes(
      'hibah',
    )
      ? 'hibah'
      : window.location.pathname.includes('harta')
      ? 'harta'
      : '';
    let urlBase = `/${moduleBaseUrl}_${pathPrefix}`;
    urlBase = `${
      pathPrefix === ''
        ? urlBase.substring(0, urlBase.length - 1)
        : urlBase
    }`;

    switch (action) {
      case 'view':
        return `${urlBase}/${recId}`;
      case 'edit':
        return `${urlBase}/${recId}/edit`;
      case 'new':
        return `${urlBase}/new`;
      case 'home':
        return urlBase;
      default:
        throw new Error('Invalid action');
    }
  };

  const url = getUrl(action, recId);

  if (returnUrl) {
    return url;
  } else {
    getHistory().push(url);
  }
};

/**
 * Navigates to the home page based on the current URL path.
 *
 * If the path includes 'hibah', navigates to the Hibah list page.
 * If the path includes 'harta', navigates to the Harta Sepencarian list page.
 * Otherwise, navigates to the Will list page.
 *
 * @return {void}
 */
export const multiPlatformHomeNavigator = () => {
  if (window.location.pathname.includes('hibah')) {
    getHistory().push('/hibahListPage');
  } else if (window.location.pathname.includes('harta')) {
    getHistory().push('/hartasepencarianListPage');
  } else {
    getHistory().push('/willListPage');
  }
};

/**
 * Checks if an expired or unpurchased plan exists for a given Client.
 *
 * This function checks the plan status and expiration dates for various services
 * such as custodian, executor, and testamentary services. It also checks for
 * unpurchased plans and returns true if any of these conditions are met.
 *
 * @param {object} item - The Client's Tenant object to check for expired or unpurchased plans.
 * @return {boolean} True if an expired or unpurchased plan exists, false otherwise.
 */
export const checkIfExpiredOrUnpurchasedPlanExist = (
  item,
) => {
  let result = false;
  let willMode = item.userSettingsWillMode ?? 'will';

  if (
    item.plan === 'free' ||
    (item.planExpiresDate &&
      moment().isAfter(moment(item.planExpiresDate)))
  ) {
    return true;
  }
  if (item?.Selected_Trustee) {
    switch (item?.Selected_Trustee?.toUpperCase()) {
      case 'PALLADIUM': {
        if (
          !item.Paid_Custodian_Palladium ||
          (item.Custodian_Palladium_ExpiryDate &&
            moment().isAfter(
              moment(item.Custodian_Palladium_ExpiryDate),
            )) ||
          !item.Paid_Executor_Palladium ||
          (item.Executor_Palladium_ExpiryDate &&
            moment().isAfter(
              moment(item.Executor_Palladium_ExpiryDate),
            )) ||
          !item.Paid_Testamentary_Palladium ||
          (item.Testamentary_Palladium_ExpiryDate &&
            moment().isAfter(
              moment(
                item.Testamentary_Palladium_ExpiryDate,
              ),
            ))
        ) {
          result = true;
        }

        break;
      }
      case 'MYPREMIER': {
        if (
          !item.Paid_Custodian_MyPremier ||
          (item.Custodian_MyPremier_ExpiryDate &&
            moment().isAfter(
              moment(item.Custodian_MyPremier_ExpiryDate),
            )) ||
          !item.Paid_Executor_MyPremier ||
          (item.Executor_MyPremier_ExpiryDate &&
            moment().isAfter(
              moment(item.Executor_MyPremier_ExpiryDate),
            )) ||
          !item.Paid_Testamentary_MyPremier ||
          (item.Testamentary_MyPremier_ExpiryDate &&
            moment().isAfter(
              moment(
                item.Testamentary_MyPremier_ExpiryDate,
              ),
            ))
        ) {
          result = true;
        }
        break;
      }
      default: {
        result = true;
        break;
      }
    }
  }
  if (willMode === 'wasiat') {
    if (!item?.Paid_Hibah) {
      result = true;
    }
    if (!item?.Paid_HartaSepencarian) {
      result = true;
    }
  }
  return result;
};

export const shortenTextToNLetters = (
  text: string,
  n: number,
) => {
  if (!text) {
    return '';
  }
  if (text?.length <= n) {
    return text;
  }
  return `${text?.substring(0, n)}...`;
};

export const convertWatchUrlToEmbedUrl = (
  watchUrl: string,
): string => {
  const watchPattern =
    /https:\/\/www\.youtube\.com\/watch\?v=([a-zA-Z0-9_-]+)/;
  if (!watchPattern.test(watchUrl)) {
    return watchUrl;
  }
  const match = watchUrl.match(watchPattern);

  if (match && match[1]) {
    const videoId = match[1];
    return `https://www.youtube.com/embed/${videoId}`;
  } else {
    throw new Error('Invalid YouTube watch URL');
  }
};
export const convertEmbedUrlToWatchUrl = (
  embedUrl: string,
): string => {
  const embedPattern =
    /https:\/\/www\.youtube\.com\/embed\/([a-zA-Z0-9_-]+)/;

  if (!embedPattern.test(embedUrl)) {
    return embedUrl;
  }
  const match = embedUrl.match(embedPattern);

  if (match && match[1]) {
    const videoId = match[1];
    return `https://www.youtube.com/watch?v=${videoId}`;
  } else {
    throw new Error('Invalid YouTube embed URL');
  }
};

export type TMediaType = {
  extension: string;
  download: boolean;
  embed: boolean;
  orgExtension: string;
};

export const checkMediaType = (url: string): TMediaType => {
  // Extract the extension from the URL or file name by searching for patterns
  const getExtension = (
    url: string,
  ): string | undefined => {
    const urlObj = new URL(url);
    const pathSegments = urlObj.pathname.split('/');
    const lastSegment = pathSegments.pop();
    return lastSegment?.split('.').pop()?.toLowerCase();
  };

  // Check for external video sources
  const youtubePattern =
    /(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const vimeoPattern =
    /vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/(?:\w+\/)?|album\/(?:\d+\/)?|video\/|)(\d+)(?:$|\/|\?)/;

  if (youtubePattern.test(url) || vimeoPattern.test(url)) {
    return {
      extension: 'mov',
      embed: true,
      download: false,
      orgExtension: null,
    };
  }

  // Get the extension from the URL or file name
  const extension = getExtension(url);

  // Define known file type extensions
  const videoExtensions = [
    'mp4',
    'mkv',
    'avi',
    'mov',
    'wmv',
    'flv',
  ];
  const imageExtensions = [
    'jpg',
    'jpeg',
    'png',
    'gif',
    'bmp',
    'svg',
    'webp',
  ];
  const pdfExtensions = ['pdf'];
  const excelExtensions = ['xls', 'xlsx', 'xlsm'];
  const wordExtensions = ['doc', 'docx'];

  // Check file type by extension
  if (extension) {
    if (videoExtensions.includes(extension)) {
      return {
        extension: 'mov',
        download: true,
        embed: false,
        orgExtension: extension,
      };
    } else if (imageExtensions.includes(extension)) {
      return {
        extension: 'img',
        download: true,
        embed: false,
        orgExtension: extension,
      };
    } else if (pdfExtensions.includes(extension)) {
      return {
        extension: 'pdf',
        download: true,
        embed: true,
        orgExtension: extension,
      };
    } else if (excelExtensions.includes(extension)) {
      return {
        extension: 'xls',
        download: true,
        embed: true,
        orgExtension: extension,
      };
    } else if (wordExtensions.includes(extension)) {
      return {
        extension: 'doc',
        download: true,
        embed: true,
        orgExtension: extension,
      };
    }
  }

  return undefined;
};

export const copyToClipboard = (
  payload: string,
  callback?: () => any,
) => {
  navigator.clipboard.writeText(
    convertEmbedUrlToWatchUrl(payload),
  );
  callback?.();
};

export const sizeInBytesToString = (
  size: number,
): string => {
  const ONE_KB = 1024;
  const ONE_MB = 1024 * 1024;
  const ONE_GB = 1024 * 1024 * 1024;

  if (size < ONE_KB) {
    return `${size} Bytes`;
  } else if (size < ONE_MB) {
    const sizeInKB = size / ONE_KB;
    return `${sizeInKB.toFixed(2)} KB`;
  } else if (size < ONE_GB) {
    const sizeInMB = size / ONE_MB;
    return `${sizeInMB.toFixed(2)} MB`;
  } else {
    const sizeInGB = size / ONE_GB;
    return `${sizeInGB.toFixed(2)} GB`;
  }
};

export const validateEmail = (email: string): boolean => {
  const validEmailFormat =
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return validEmailFormat.test(String(email).toLowerCase());
};

export const timeSince = (date: string | Date): string => {
  const givenDate = moment(date);
  const now = moment();

  const daysDifference = now.diff(givenDate, 'days');
  const weeksDifference = now.diff(givenDate, 'weeks');
  const monthsDifference = now.diff(givenDate, 'months');

  if (daysDifference < 7) {
    return daysDifference === 0
      ? 'Today'
      : `${daysDifference} day${
          daysDifference !== 1 ? 's' : ''
        } ago`;
  } else if (weeksDifference < 4) {
    return `${weeksDifference} week${
      weeksDifference !== 1 ? 's' : ''
    } ago`;
  } else {
    return `${monthsDifference} month${
      monthsDifference !== 1 ? 's' : ''
    } ago`;
  }
};

export const formatDate = (
  payload: string | Date,
): string => {
  const date: Moment = moment(payload);

  // Format the date to 'yyyy-mm-dd hh:mm'
  return date.format('YYYY-MM-DD HH:mm');
};

export const toFirstLetterUppercase = (
  input: string,
): string => {
  return input
    .split(/(?=[A-Z])|[_\s]/)
    .map((word) => word.charAt(0).toUpperCase())
    .join('');
};

export const pascalCaseToNormal = (
  text: string,
): string => {
  const withSpaces = text.replace(/([A-Z])/g, ' $1').trim();
  return withSpaces.replace(
    /\w\S*/g,
    (word) =>
      word.charAt(0).toUpperCase() +
      word.slice(1).toLowerCase(),
  );
};
