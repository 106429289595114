import React, {
  // useEffect,
  useState,
} from 'react';
import { i18n } from 'src/i18n';
import InfoIcon from '@material-ui/icons/Info';
import { Checkbox } from 'antd';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
  // Button,
  IconButton,
  Grid,
  Link,
} from '@material-ui/core';
//import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {
  makeStyles,
  useTheme,
  // withStyles,
} from '@material-ui/core/styles';
import parse from 'html-react-parser';
import Speech from 'speak-tts';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
// import { info } from 'console';
// import personalIcon from '@material-ui/icons/PermIdentity';
import { CloseOutlined } from '@ant-design/icons';
import { Button as Button2 } from 'antd';
import { useSelector } from 'react-redux';
import selectors from 'src/modules/auth/authSelectors';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';
import userSettingsSelectors from 'src/modules/userSettings/userSettingsSelectors';
import TenantCurrent from 'src/modules/tenant/tenantCurrent';
import { isMobile } from 'react-device-detect';
// import authSelectors from 'src/modules/auth/authSelectors';
import EncryptionTool from 'src/modules/encryption/dataEncryption';
const HeaderWithImages = (props) => {
  const isHibah =
    window.location.pathname.includes('hibah');
  const targetSection =
    isHibah && props.section === 'executor'
      ? 'trustee'
      : props.section;

  const currentPlan = useSelector(
    selectors.selectCurrentPlan,
  );

  const showLanding = TenantCurrent.getShowAdditionalInfo();
  // const [open, setOpen] = React.useState(false);
  const [open, setOpen] = React.useState(showLanding);
  const [noShowAgain, setNoShowAgain] = useState(
    !showLanding,
  );

  const currentUser = useSelector(
    selectors.selectCurrentUser,
  );
  const currentTenant = useSelector(
    selectors.selectCurrentTenant,
  );

  const userSettings = useSelector(
    userSettingsSelectors.selectUserSettings,
  );

  const remote =
    currentTenant['createdBy'] !== currentUser['id'];
  const isWasiatWillMode = remote
    ? AuthCurrentTenant.getWillMode() === 'wasiat' ?? false
    : userSettings?.userSettingsWillMode === 'wasiat' ??
      false;

  const showMore = [
    'distribution',
    'legalGuardian',
    'witness',
    'beneficiary',
    'executor',
    // 'lastInstructions',
    'pledge',
    'pet',
    // 'assetsDistribution',
  ];

  const infoArray = [
    {
      title: 'profile',
      label: i18n('auth.profile.title'),
      modalTitle: i18n('auth.profile.testator'),
      helpInfo: i18n('auth.profile.helpInfo'),
      extraInfo: [
        i18n('dashboard.details.profile.info1'),
        i18n('dashboard.details.profile.info2'),
        i18n('dashboard.details.profile.info3'),
        i18n('dashboard.details.profile.info4'),
        i18n('dashboard.details.profile.info5'),
        i18n('dashboard.details.profile.info6'),
      ],
      extraInfoSpacing: [2, 2, 2, 1, 1],
      playText:
        i18n('dashboard.details.profile.info1') +
        i18n('dashboard.details.profile.info2') +
        i18n('dashboard.details.profile.info3') +
        i18n('dashboard.details.profile.info4') +
        i18n('dashboard.details.profile.info5') +
        i18n('dashboard.details.profile.info6'),
    },
    {
      title: 'beneficiary',
      label: isWasiatWillMode
        ? i18n('mainPage.list.wasiat.beneficiary')
        : i18n('entities.beneficiary.menu'),
      modalTitle: i18n('entities.beneficiary.modalTitle'),
      helpInfo: i18n(
        'entities.beneficiary.enumerators.helpInfo',
      ),
      extraInfo: [
        i18n('dashboard.details.beneficiaries.info1'),
        i18n('dashboard.details.beneficiaries.info2'),
        i18n('dashboard.details.beneficiaries.info3'),
      ],
      extraInfoSpacing: [2, 2],
      playText:
        i18n('dashboard.details.beneficiaries.info1') +
        i18n('dashboard.details.beneficiaries.info2') +
        i18n('dashboard.details.beneficiaries.info3'),
      videoUrl:
        'https://player.vimeo.com/video/' +
        (i18n('app.willTestament') === 'Wasiat'
          ? '853621725'
          : '787427122'),
    },
    {
      title: 'executor',
      label: i18n('entities.executor.menu'),
      modalTitle: i18n('entities.executor.modalTitle'),
      helpInfo: i18n(
        'entities.executor.enumerators.helpInfo',
      ),
      extraInfo: [
        i18n('dashboard.details.executor.info1'),
        i18n('dashboard.details.executor.info2'),
        i18n('dashboard.details.executor.info3'),
        i18n('dashboard.details.executor.info4.title'),
        i18n('dashboard.details.executor.info4.list1'),
        i18n('dashboard.details.executor.info4.list2'),
        i18n('dashboard.details.executor.info4.list3'),
        i18n('dashboard.details.executor.info4.list4'),
        i18n('dashboard.details.executor.info4.list5'),
        i18n('dashboard.details.executor.info4.list6'),
        i18n('dashboard.details.executor.info4.list7'),
      ],
      extraInfoSpacing: [2, 2, 2, 1, 1, 1, 1, 1, 1, 1],
      playText:
        i18n('dashboard.details.executor.info1') +
        i18n('dashboard.details.executor.info2') +
        i18n('dashboard.details.executor.info3') +
        i18n('dashboard.details.executor.info4.title') +
        i18n('dashboard.details.executor.info4.list1') +
        i18n('dashboard.details.executor.info4.list2') +
        i18n('dashboard.details.executor.info4.list3') +
        i18n('dashboard.details.executor.info4.list4') +
        i18n('dashboard.details.executor.info4.list5') +
        i18n('dashboard.details.executor.info4.list6') +
        i18n('dashboard.details.executor.info4.list7'),
      videoUrl:
        'https://player.vimeo.com/video/' +
        (i18n('app.willTestament') === 'Wasiat'
          ? '841468082'
          : '787481133'),
    },
    {
      title: 'account',
      label: i18n('entities.account.menu'),
      modalTitle: i18n('entities.account.modalTitle'),
      helpInfo: i18n(
        'entities.account.enumerators.helpInfo',
      ),
      extraInfo: [
        i18n('dashboard.details.account.info1'),
        i18n('dashboard.details.account.info2'),
        i18n('dashboard.details.account.info3'),
        i18n('dashboard.details.account.info4'),
        i18n('dashboard.details.account.info5'),
      ],
      extraInfoSpacing: [2, 2, 2, 2],
      playText:
        i18n('dashboard.details.account.info1') +
        i18n('dashboard.details.account.info2') +
        i18n('dashboard.details.account.info3') +
        i18n('dashboard.details.account.info4') +
        i18n('dashboard.details.account.info5'),
    },
    {
      title: 'property',
      label: i18n('entities.property.menu'),
      modalTitle: i18n('entities.property.modalTitle'),
      helpInfo: i18n(
        'entities.property.enumerators.helpInfo',
      ),
      extraInfo: [
        i18n('dashboard.details.property.info1'),
        i18n('dashboard.details.property.info2'),
        i18n('dashboard.details.property.info3'),
      ],
      extraInfoSpacing: [2, 2],
      playText:
        i18n('dashboard.details.property.info1') +
        i18n('dashboard.details.property.info2') +
        i18n('dashboard.details.property.info3'),
    },
    {
      title: 'otherAsset',
      label: i18n('entities.otherAsset.menu'),
      modalTitle: i18n('entities.otherAsset.modalTitle'),
      helpInfo: i18n(
        'entities.otherAsset.enumerators.helpInfo',
      ),
      extraInfo: [
        i18n('dashboard.details.assets.info1.title'),
        i18n('dashboard.details.assets.info1.list1'),
        i18n('dashboard.details.assets.info1.list2'),
        i18n('dashboard.details.assets.info1.list3'),
        i18n('dashboard.details.assets.info2'),
        i18n('dashboard.details.assets.info3'),
      ],
      extraInfoSpacing: [2, 1, 1, 2, 2],
      playText:
        i18n('dashboard.details.assets.info1.title') +
        i18n('dashboard.details.assets.info1.list1') +
        i18n('dashboard.details.assets.info1.list2') +
        i18n('dashboard.details.assets.info1.list3') +
        i18n('dashboard.details.assets.info2') +
        i18n('dashboard.details.assets.info3'),
    },
    {
      title: 'digital',
      label: i18n('entities.digital.menu'),
      modalTitle: i18n('entities.digital.modalTitle'),
      helpInfo: i18n('entities.digital.helpinfo'),
      extraInfo: [
        i18n('dashboard.details.digital.title'),
        i18n('dashboard.details.digital.info1'),
        i18n('dashboard.details.digital.info2'),
        i18n('dashboard.details.digital.info3'),
        i18n('dashboard.details.digital.info4'),
      ],
      extraInfoSpacing: [2, 2, 2, 2, 2],
      playText:
        i18n('dashboard.details.digital.title') +
        i18n('dashboard.details.digital.info1') +
        i18n('dashboard.details.digital.info2') +
        i18n('dashboard.details.digital.info3') +
        i18n('dashboard.details.digital.info4'),
    },
    {
      title: 'pledge',
      label: i18n('entities.pledge.menu'),
      modalTitle: i18n('entities.pledge.modalTitle'),
      helpInfo: i18n('entities.pledge.helpinfo'),
      extraInfo: [i18n('dashboard.details.pledge.info1')],
      extraInfoSpacing: [2, 2],
      playText: i18n('dashboard.details.pledge.info1'),
      videoUrl: 'https://player.vimeo.com/video/789596237',
    },
    {
      title: 'debt',
      label: i18n('entities.debt.menu'),
      modalTitle: i18n('entities.debt.modalTitle'),
      helpInfo: i18n('entities.debt.enumerators.helpInfo'),
      extraInfo: [
        i18n('dashboard.details.debt.info1'),
        i18n('dashboard.details.debt.info2'),
        i18n('dashboard.details.debt.info3'),
      ],
      extraInfoSpacing: [2, 2],
      playText:
        i18n('dashboard.details.debt.info1') +
        i18n('dashboard.details.debt.info2') +
        i18n('dashboard.details.debt.info3'),
    },
    {
      title: 'distribution',
      label: i18n('entities.residuary.menu'),
      modalTitle: i18n('entities.residuary.modalTitle'),
      helpInfo: i18n(
        'entities.residuary.enumerators.helpInfo_free',
      ),
      extraInfo: [
        i18n('dashboard.details.residuary.free.info1'),
      ],
      extraInfoSpacing: [2],

      playText: i18n(
        'dashboard.details.residuary.free.info1',
      ),
      videoUrl: 'https://player.vimeo.com/video/787427316',
    },
    {
      title: 'lastInstructions',
      label: i18n('entities.personalMessages.title'),
      modalTitle: i18n('entities.personalMessages.title'),
      helpInfo: i18n(
        'entities.lastInstructions.enumerators.helpInfo',
      ),
      extraInfo: [
        i18n('dashboard.details.personalMessage.info1'),
        // i18n('dashboard.details.funeral.info1'),
        // i18n('dashboard.details.funeral.info2'),
      ],
      extraInfoSpacing: [2],
      playText:
        i18n('dashboard.details.funeral.info1') +
        i18n('dashboard.details.funeral.info2'),
      videoUrl:
        'https://player.vimeo.com/video/' +
        (i18n('app.willTestament') === 'Wasiat'
          ? '853622405'
          : '801506578'),
    },
    {
      title: 'legalGuardian',
      label: i18n('entities.legalGuardian.menu'),
      modalTitle: i18n('entities.legalGuardian.modalTitle'),
      // helpInfo: i18n(
      //   'entities.lastInstructions.enumerators.helpInfo',
      // ),
      helpInfo: i18n(
        'entities.legalGuardian.enumerators.helpInfo',
      ),
      extraInfo: [
        i18n('dashboard.details.legalguardian.info1'),
        i18n('dashboard.details.legalguardian.info2'),
        i18n('dashboard.details.legalguardian.info3.title'),
        i18n('dashboard.details.legalguardian.info3.list1'),
        i18n('dashboard.details.legalguardian.info3.list2'),
        i18n('dashboard.details.legalguardian.info3.list3'),
        i18n('dashboard.details.legalguardian.info3.list4'),
        i18n('dashboard.details.legalguardian.info4'),
      ],
      extraInfoSpacing: [2, 2, 1, 1, 1, 1, 2],
      playText:
        i18n('dashboard.details.legalguardian.info1') +
        i18n('dashboard.details.legalguardian.info2') +
        i18n(
          'dashboard.details.legalguardian.info3.title',
        ) +
        i18n(
          'dashboard.details.legalguardian.info3.list1',
        ) +
        i18n(
          'dashboard.details.legalguardian.info3.list2',
        ) +
        i18n(
          'dashboard.details.legalguardian.info3.list3',
        ) +
        i18n(
          'dashboard.details.legalguardian.info3.list4',
        ) +
        i18n('dashboard.details.legalguardian.info4'),
      videoUrl:
        'https://player.vimeo.com/video/' +
        (i18n('app.willTestament') === 'Wasiat'
          ? '841468153'
          : '799809535'),
    },
    {
      title: 'summary',
      label: i18n('entities.Export.summaryTitle'),
      modalTitle: i18n('entities.Export.modalTitle'),
      helpInfo: i18n('entities.Export.summaryDesc'),
      extraInfo: [
        i18n('dashboard.details.legalguardian.info1'),
        i18n('dashboard.details.legalguardian.info2'),
      ],
      extraInfoSpacing: [2, 2],
      playText:
        i18n('dashboard.details.legalguardian.info1') +
        i18n('dashboard.details.legalguardian.info2'),
    },
    {
      title: 'contactList',
      label: i18n('entities.contactList.menu'),
      modalTitle: i18n('entities.contactList.modalTitle'),
      helpInfo: i18n(
        'entities.contactList.enumerators.helpInfo',
      ),
      extraInfo: [
        i18n('entities.contactList.enumerators.helpInfo'),
      ],
      extraInfoSpacing: [2],
      playText: i18n(
        'entities.contactList.enumerators.helpInfo',
      ),
    },
    {
      title: 'witness',
      label: i18n('entities.witness.menu'),
      modalTitle: i18n('entities.witness.modalTitle'),
      helpInfo: i18n(
        'entities.witness.enumerators.helpInfo',
      ),
      extraInfo: [
        i18n('dashboard.details.witness.info1'),
        i18n('dashboard.details.witness.info2'),
      ],
      extraInfoSpacing: [2],
      playText:
        i18n('dashboard.details.witness.info1') +
        i18n('dashboard.details.witness.info2'),
      videoUrl:
        'https://player.vimeo.com/video/' +
        (i18n('app.willTestament') === 'Wasiat'
          ? '841468297'
          : '802639032'),
    },
    {
      title: 'healthInfo',
      // label: i18n('entities.healthInfo.menu'),
      // modalTitle: i18n('entities.healthInfo.modalTitle'),
      label: i18n('entities.emergencyCard.menu'),
      modalTitle: i18n('entities.emergencyCard.modalTitle'),
      helpInfo: i18n(
        'entities.healthInfo.enumerators.helpInfo',
      ),
      extraInfo: [
        i18n('entities.healthInfo.enumerators.helpInfo'),
      ],
      extraInfoSpacing: [2],
      playText: i18n(
        'entities.healthInfo.enumerators.helpInfo',
      ),
    },
    {
      title: 'emergencyCard',
      label: i18n('entities.emergencyCard.menu'),
      modalTitle: i18n('entities.emergencyCard.modalTitle'),
      helpInfo: i18n(
        'entities.emergencyCard.enumerators.helpInfo',
      ),
      extraInfo: [
        i18n('entities.emergencyCard.enumerators.helpInfo'),
      ],
      extraInfoSpacing: [2],
      playText: i18n(
        'entities.emergencyCard.enumerators.helpInfo',
      ),
    },
    {
      title: 'insurance',
      label: i18n('entities.insurance.menu'),
      modalTitle: i18n('entities.insurance.modalTitle'),
      helpInfo: i18n(
        'entities.insurance.enumerators.helpInfo',
      ),
      extraInfo: [
        i18n('entities.insurance.enumerators.helpInfo'),
      ],
      extraInfoSpacing: [2],
      playText: i18n(
        'entities.insurance.enumerators.helpInfo',
      ),
    },
    {
      title: 'pledge',
      label: i18n('entities.pledge.menu'),
      modalTitle: i18n('entities.pledge.modalTitle'),
      helpInfo: i18n('entities.pledge.helpinfo'),
      extraInfo: [i18n('dashboard.details.pledge.info1')],
      extraInfoSpacing: [2, 2],
      playText: [i18n('dashboard.details.pledge.info1')],
    },
    {
      title: 'guardians',
      label: i18n('entities.guardians.menu'),
      modalTitle: i18n('entities.guardians.modalTitle'),
      helpInfo: i18n('entities.guardians.helpinfo'),
      extraInfo: [
        i18n('dashboard.details.guardians.info1'),
      ],
      extraInfoSpacing: [2, 2],
      playText: [i18n('dashboard.details.guardians.info1')],
    },
    {
      title: 'vehicle',
      label: i18n('entities.vehicle.menu'),
      modalTitle: i18n('entities.vehicle.modalTitle'),
      helpInfo: i18n(
        'entities.vehicle.enumerators.helpInfo',
      ),
      extraInfo: [
        i18n('dashboard.details.vehicle.info1'),
        i18n('dashboard.details.vehicle.info2'),
        i18n('dashboard.details.vehicle.info3'),
      ],
      extraInfoSpacing: [2, 2],
      playText:
        i18n('dashboard.details.vehicle.info1') +
        i18n('dashboard.details.vehicle.info2') +
        i18n('dashboard.details.vehicle.info3'),
    },
    {
      title: 'pet',
      label: i18n('entities.pet.menu'),
      modalTitle: i18n('entities.pet.modalTitle'),
      helpInfo: i18n('entities.pet.enumerators.helpInfo'),
      //extraInfo: [
      //i18n('dashboard.details.pet.info1'),
      //],
      extraInfo: [
        i18n('dashboard.details.pets.info1'),
        i18n('dashboard.details.pets.info2'),
        i18n('dashboard.details.pets.info3'),
      ],
      extraInfoSpacing: [2, 2],
      //playText: i18n('dashboard.details.pet.info1'),
      videoUrl:
        'https://player.vimeo.com/video/' +
        (i18n('app.willTestament') === 'Wasiat'
          ? '841468153'
          : '800455672'),
    },
    {
      title: 'trustee',
      label: i18n('entities.trustee.menu'),
      modalTitle: i18n('entities.trustee.modalTitle'),
      helpInfo: i18n(
        'entities.trustee.enumerators.helpInfo',
      ),
      extraInfo: [
        i18n('dashboard.details.trustee.info1'),
        i18n('dashboard.details.trustee.info2.list1'),
        i18n('dashboard.details.trustee.info2.list2'),
        i18n('dashboard.details.trustee.info2.list3'),
      ],
      extraInfoSpacing: [2, 1, 1],
      playText:
        i18n('dashboard.details.trustee.info1') +
        i18n('dashboard.details.trustee.info2.list1') +
        i18n('dashboard.details.trustee.info2.list2') +
        i18n('dashboard.details.trustee.info2.list3'),
    },
    {
      title: 'financialPlanning',
      label: i18n('entities.financialPlanning.menu'),
    },
    {
      title: 'myHeir',
      label: i18n('app.family'),
      modalTitle: i18n('entities.beneficiary.modalTitle'),
      helpInfo: i18n(
        'entities.beneficiary.enumerators.helpInfo',
      ),
      extraInfo: [
        i18n('dashboard.details.beneficiaries.info1'),
        i18n('dashboard.details.beneficiaries.info2'),
        i18n('dashboard.details.beneficiaries.info3'),
      ],
      extraInfoSpacing: [2, 2],
      playText:
        i18n('dashboard.details.beneficiaries.info1') +
        i18n('dashboard.details.beneficiaries.info2') +
        i18n('dashboard.details.beneficiaries.info3'),
    },
    {
      title: 'myFamilyTree',
      label: i18n('app.familyTree'),
      modalTitle: i18n('entities.beneficiary.modalTitle'),
      helpInfo: i18n(
        'entities.beneficiary.enumerators.helpInfo',
      ),
      extraInfo: [
        i18n('dashboard.details.beneficiaries.info1'),
        i18n('dashboard.details.beneficiaries.info2'),
        i18n('dashboard.details.beneficiaries.info3'),
      ],
      extraInfoSpacing: [2, 2],
      playText:
        i18n('dashboard.details.beneficiaries.info1') +
        i18n('dashboard.details.beneficiaries.info2') +
        i18n('dashboard.details.beneficiaries.info3'),
    },
    {
      title: 'specificGift',
      label: i18n('app.specificGift'),
      modalTitle: i18n('entities.specificGift.modalTitle'),
      helpInfo: i18n(
        'entities.specificGift.enumerators.helpInfo',
      ),
    },
    {
      title: 'assetsDistribution',
      label: i18n('app.assetsDistribution'),
      modalTitle: i18n('assetsDistribution.modalTitle'),
      helpInfo: isWasiatWillMode
        ? i18n('assetsDistribution.helpInfoWasiat')
        : i18n('assetsDistribution.helpInfo'),
    },
    {
      title: 'dependents',
      label: i18n('app.dependents'),
      modalTitle: i18n('entities.dependents.modalTitle'),
      helpInfo: i18n(
        'entities.dependents.enumerators.helpInfo',
      ),
    },
  ];

  const useStyles = makeStyles((theme) => ({
    header: {
      display: 'flex',
      alignItems: 'center',
      height: 40,
      paddingLeft: theme.spacing(4),
      // backgroundColor: '#fff',
      borderTopLeftRadius: '8px',
      borderTopRightRadius: '8px',
    },
    img: {
      minHeight: 120,
      height: 240,
      display: 'block',
      overflow: 'hidden',
      width: '100%',
    },
    header_container: {
      position: 'relative',
      zoom: 1,
      maxWidth: '1300px',
    },
    header_container_text: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      width: '100%',
      color: '#fff',
      // backgroundColor: '#ede7f6',
      opacity: '0.9',
      transform: 'translate(-50%, -50%)',
      zIndex: 1,
    },
    topLeftBox: {
      position: 'absolute',
      backgroundColor: '#ede7f6',
      right: '5%',
      top: '8%',
      opacity: '0.9',
      zIndex: 1,
    },
    audiobutton: {
      border: 'solid 1px rgba(255,255,255,1)',
      borderRadius: 6,
    },
    header_Grid: {
      borderTopLeftRadius: '8px',
      borderTopRightRadius: '8px',
      // backgroundImage:
      //   'linear-gradient(90deg, rgb(18,36,75) 30%,rgb(0,73,197))',
      backgroundImage:
        i18n('app.willTestament') === 'Wasiat'
          ? 'linear-gradient(168deg, rgba(0,6,36,1) 30%, rgba(48,255,0,1) 100%)'
          : 'linear-gradient(267.03deg, #8B00D2 1.88%, #161A4C 41.23%)',
      margin: '0px',
      width: '100%',
      minHeight: '200px',
      padding: '3%',
    },
  }));

  const classes = useStyles();
  // const theme = useTheme();
  useTheme();
  const getInfo = (type) => {
    for (var i = 0; i < infoArray.length; i++) {
      if (infoArray[i].title === targetSection) {
        if (type === 'label') {
          return infoArray[i].label;
        } else if (type === 'modalTitle') {
          return infoArray[i].modalTitle;
        }
        // else if (type === 'imgPath') {
        //   return infoArray[i].imgPath;
        // }
        else if (type === 'helpInfo') {
          return infoArray[i].helpInfo;
        } else if (type === 'playText') {
          return infoArray[i].playText;
        } else if (type === 'extraInfo') {
          var temp = '';
          if (infoArray[i].extraInfo !== undefined) {
            for (
              var j = 0;
              j < infoArray[i].extraInfo.length;
              j++
            ) {
              if (temp === '') {
                temp += infoArray[i].extraInfo[j];
              } else {
                if (
                  infoArray[i].extraInfoSpacing !==
                  undefined
                ) {
                  for (
                    var k = 0;
                    k <
                    infoArray[i].extraInfoSpacing[j - 1];
                    k++
                  ) {
                    temp += '<br />';
                  }
                }
                temp += infoArray[i].extraInfo[j];
              }
            }
          }
          return temp;
        } else if (type === 'videoUrl') {
          return infoArray[i].videoUrl;
        }
      }
    }
  };

  // useEffect(() => {
  //   if (bannerSpeech.speaking()) {
  //     bannerSpeech.cancel()
  //   }
  // });

  const [speech, setSpeech] = useState(new Speech());
  // const [bannerSpeech, setBannerSpeech] = useState(new Speech());

  // bannerSpeech.init({
  //   volume: 1,
  //   lang: 'en-GB',
  //   rate: 0.8,
  //   pitch: 1,
  //   voice: 'Google UK English Female',
  //   splitSentences: true,
  // })

  const handleClickOpen = () => {
    setOpen(true);

    speech.init({
      volume: 1,
      lang:
        EncryptionTool.decrypt(
          localStorage.getItem('language'),
        ) === 'en'
          ? 'en-GB'
          : 'zh-CN',
      rate: 0.8,
      pitch: 1,
      voice:
        EncryptionTool.decrypt(
          localStorage.getItem('language'),
        ) === 'en'
          ? 'Google UK English Female'
          : 'Google 普通话（中国大陆）',
      splitSentences: true,
    });
  };

  const handleClickOpenExecutor = (type) => {
    setOpen(true);

    speech.init({
      volume: 1,
      lang: 'en-GB',
      rate: 0.8,
      pitch: 1,
      voice: 'Google UK English Female',
      splitSentences: true,
    });
  };

  const handleClose = () => {
    TenantCurrent.updateAdditionalInfo(noShowAgain);
    setOpen(false);
    speech.cancel();
  };

  const handlePlay = () => {
    speech.speak({
      text: getInfo('playText'),
    });
  };

  // const handleBannerPlay = () => {
  //   if (bannerSpeech.speaking()) {
  //     bannerSpeech.cancel();
  //   } else {
  //     bannerSpeech.speak({
  //       text: getInfo('helpInfo'),
  //     });
  //   }

  //   // bannerSpeech.speak({
  //   //   text: getInfo('helpInfo'),
  //   // });
  // };

  return (
    <>
      {props.section !== null && (
        <>
          <div className={classes.header_container}>
            <Grid
              container
              spacing={3}
              className={classes.header_Grid}
            >
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Typography
                  variant="h4"
                  style={{
                    fontWeight: 'bold',
                    color: '#fff',
                  }}
                >
                  {getInfo('label')}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={8}>
                <Typography
                  style={{ fontSize: 16, color: '#fff' }}
                  variant="subtitle2"
                >
                  {getInfo('helpInfo')}
                  {/* <div>
                    {getInfo('helpInfo') + '  '}
                    <IconButton
                      onClick={handleBannerPlay}
                      style={{
                        width: 5,
                        height: 5,
                        color: '#f0f8ff',
                      }}
                    >
                      <VolumeUpIcon />
                    </IconButton>
                  </div> */}
                </Typography>
                <br></br>
                <>
                  {showMore.includes(targetSection) && (
                    <>
                      <Tooltip
                        title={i18n('common.showDetail')}
                      >
                        <IconButton
                          onClick={handleClickOpen}
                          style={{
                            backgroundColor: '#00FFBC',
                            width: 5,
                            height: 5,
                          }}
                        >
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                      <Link
                        onClick={handleClickOpen}
                        style={{
                          fontSize: 14,
                          color: '#fff',
                        }}
                      >
                        {i18n('common.moreInfo')}
                      </Link>
                    </>
                  )}
                </>
              </Grid>
            </Grid>
          </div>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            fullScreen={isMobile ? true : false}
          >
            <DialogTitle id="alert-dialog-title">
              <>
                {targetSection === 'profile'
                  ? infoArray[0].modalTitle
                  : getInfo('modalTitle')}
                {targetSection !== 'pet' && (
                  <IconButton
                    className={classes.audiobutton}
                    onClick={handlePlay}
                  >
                    <VolumeUpIcon />
                  </IconButton>
                )}
              </>
            </DialogTitle>
            <iframe
              src={getInfo('videoUrl')}
              width="100%"
              height="360"
              allow="autoplay; fullscreen; picture-in-picture"
            ></iframe>
            <DialogContent>
              <DialogContentText
                id="alert-dialog-description"
                style={{
                  textAlign: 'justify',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                {parse(getInfo('extraInfo'))}
              </DialogContentText>
            </DialogContent>
            <DialogActions
              style={{ justifyContent: 'left' }}
            >
              <Button2
                onClick={handleClose}
                icon={<CloseOutlined />}
                //type= "primary"
              >
                {i18n('common.close')}
              </Button2>

              <Checkbox
                key="showLanding"
                checked={noShowAgain}
                onChange={(e) => {
                  setNoShowAgain(e.target.checked);
                }}
              >
                {i18n('dashboard.landingModal.btndoShow')}
              </Checkbox>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
};

export default HeaderWithImages;
